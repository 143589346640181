import React, { useState, useEffect, useRef } from 'react';
import { PiCircleFill, PiDot, PiPaperPlaneTilt } from 'react-icons/pi';
import notificationSound from './ui/mensagem.mp3';


const Contextos = () => {
    const [mensagens, setMensagens] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [, setMsgPergunta] = useState(false);
    const [digitando, setDigitando] = useState(false);
    const [token,] = useState(localStorage.getItem('token'));
  
    const mensagensEndRef = useRef(null);
    const audio = new Audio(notificationSound);

    useEffect(() => {
        const historicoMensagens = localStorage.getItem('historicoMensagens');
        if (historicoMensagens) {
          setMensagens(JSON.parse(historicoMensagens));
          setMsgPergunta(true);
        } 
        // eslint-disable-next-line
      }, []);
    
      useEffect(() => {
        localStorage.setItem('historicoMensagens', JSON.stringify(mensagens));
        if (mensagensEndRef.current) {
          mensagensEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [mensagens]);
    
      const linkClicavel = (text) => {
        const urlRegex = /https?:\/\/[^\s]+/g;
        return text.split(urlRegex).reduce((prev, current, i) => {
          const match = text.match(urlRegex);
          if (!match) return prev.concat(current);
          return prev.concat(
            <React.Fragment key={i}>
              {current}
              <a href={match[i]} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                {match[i]}
              </a>
            </React.Fragment>
          );
        }, []);
      };
    
      const atualizarStatusMensagem = (index, novoStatus) => {
        setMensagens((mensagensAnteriores) => {
          const novasMensagens = [...mensagensAnteriores];
          novasMensagens[index].status = novoStatus;
          return novasMensagens;
        });
      };
    
      const handleSendMensagem = async () => {
        const novaMensagem = {
          remetente: 'Você',
          texto: inputValue,
          hora: new Date(),
          status: 'enviado',
        };
        setMensagens([...mensagens, novaMensagem]);
        setInputValue('');
        setDigitando(true);
    
        const indexUltimaMensagem = mensagens.length;
    
        try {
          const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`,
            },
            credentials: 'include',
            body: JSON.stringify({ pergunta: inputValue }),
          });
    
          if (!response.ok) {
            throw new Error(`Erro de rede: ${response.status} ${response.statusText}`);
          }
    
          const data = await response.json();
    
          if (!data.resposta) {
            throw new Error('Resposta inválida da API');
          }
    
          setMensagens((prevMensagens) => [
            ...prevMensagens,
            { remetente: 'Assistente', texto: data.resposta, hora: new Date(), status: 'lido' },
          ]);
    
        } catch (error) {
          console.error('Erro ao enviar mensagem:', error);
    
          setMensagens((prevMensagens) => [
            ...prevMensagens,
            { remetente: 'Assistente', texto: 'Desculpe, ocorreu um erro ao processar sua mensagem, tente novamente mais tarde.', hora: new Date(), status: 'lido' },
          ]);
        } finally {
          audio.play();
          atualizarStatusMensagem(indexUltimaMensagem, 'lido');
          setDigitando(false);
        }
      };  

  return (
    <div className='px-5 pt-5 w-full h-full'>
      <div className='bg-white rounded-lg border shadow-2xl w-full h-[90%]'>
        <div className='flex flex-col h-[100%]'>
          
            <div className='bg-cor_principal rounded-t-xl h-15 w-full'>
                <div className='flex items-center'>
                    <PiCircleFill color='white' size={90} className='ml-5'/>  
                    <h1 className='text-white text-2xl font-semibold'>Minha_Empresa</h1>
                </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4 bg-fundo_chat h-full shadow-2xl">
                {mensagens.map((mensagem, index) => (
                <div key={index} className={`flex mb-2 ${mensagem.remetente === 'Você' ? 'justify-end' : 'justify-start'}`}>
                    <div className="flex items-start">
                    <div className={`p-2 rounded-lg text-sm text-white relative max-w-[80vw] ${mensagem.remetente === 'Assistente' ? 'bg-chat_cor_1 ml-2 text-cor_1' : 'bg-chat_cor_2 mr-2 text-left text-cor_2'} `} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <div style={{ whiteSpace: 'pre-wrap' }}>{linkClicavel(mensagem.texto)}</div>
                        <div className={`${mensagem.remetente === 'Assistente' ? 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] bg_chat_tick rotate-[-40deg] bottom-[-5px] -ml-[15px]' : 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] bg_chat_tick_2 mt-2 right-0 rotate-[40deg] bottom-[-5px] -mr-[7px]' }`} />
                    </div>
                    </div>
                </div>
                ))}
                {digitando && (
                    <div className="flex mb-2 justify-start">
                        <div className="flex items-start">
                        <div className="p-2 rounded-md text-sm bg-chat_cor_1 text-left">
                            <div className="font-bold"></div>
                            <div className='flex -mb-6 -mt-4'>
                            <PiDot color='white' size={60} className='animate-bounce -mr-10 -ml-5' />
                            <PiDot color='white' size={60} className='animate-bounce -mr-10' style={{ animationDelay: '0.2s' }} />
                            <PiDot color='white' size={60} className='animate-bounce -mr-4' style={{ animationDelay: '0.4s' }} />
                            </div>
                        </div>
                        </div>
                    </div>
                )}
                <div ref={mensagensEndRef} />
            </div>

            <div className="p-3 flex flex-col w-full border-t border-gray-400 bg-white rounded-b-xl relative">
                <div className='flex'>
                    <textarea
                        type="text"
                        placeholder="Digite uma mensagem..."
                        className="w-full mr-10 p-2 pr-[34px] rounded-2xl bg-white  border border-gray-400 mb-[10px] resize-none focus:outline-none"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSendMensagem();
                        }
                        }}
                        rows={1}
                    />
                    <PiPaperPlaneTilt size={40} color='white' className="absolute right-0 mr-2 bg-cor_principal p-[6px] rounded-full" onClick={handleSendMensagem} />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Contextos;
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiArrowLeftBold, PiArrowRightBold, PiCircleFill, PiFilePdf, PiGlobeSimpleThin, PiPaperPlaneTilt, PiUpload, PiX, PiXBold } from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import FotoPerfil from './fotoPerfil';
import Estilos from './estilos';

function Cadastro({ setShowCadastro }) {
  const navigate = useNavigate();
  const inputArquivosPDF = useRef(null);
  const [etapa, setEtapa] = useState(1);
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [arquivos, setArquivos] = useState([]);
  const [nomeAssistente, setNomeAssistente] = useState('Nome_Do_Assistente')
  const [mensagens, ] = useState([
    { "remetente": "Você", "texto": "Olá bom dia?", "status": "enviado" },
    { "remetente": "Assistente", "texto": "Bom dia.", "status": "lido" }
  ]);

  const handleImagemUpload = (imagemCortada) => {
    setFotoPerfil(imagemCortada);
  };

  const handleFileUpload = (e) => {
    const newFiles = Array.from(e.target.files).filter(file => file.type === 'application/pdf');
    setArquivos(prevFiles => [...prevFiles, ...newFiles]);
    e.target.value = '';
  };

  const removeFile = (index) => {
    setArquivos(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const pageVariants = {
    initial: { opacity: 0, x: 20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 40 },
  };

  return (
    <div className='h-full'>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div onClick={() => { setShowCadastro(false) }} className='fixed inset-0 bg-black bg-opacity-50 z-10' />
        <div className="bg-white p-5 rounded-lg z-20">
          <div className='flex flex-col'>
            <div className='flex justify-between'>
              <h1 className='text-[35px] md:text-[25px] font-bold'>Criar Conta</h1>
              <PiXBold onClick={() => { setShowCadastro(false) }} size={40} className='md:m-0 m-2 cursor-pointer' />
            </div>

            <AnimatePresence mode="wait">
              {etapa === 1 && (
                <motion.div
                  key="etapa1"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <h1 className='font-bold md:text-[15px] mt-2'>Nome de Usuário</h1>
                  <input placeholder='Nome de Usuário' className='p-1 mt-1 w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />
                  <h1 className='text-sm text-gray-400'>Nome para login</h1>

                  <div className='flex gap-10'>
                    <div className='flex flex-col'>
                      <h1 className='font-bold md:text-[15px] mt-6'>Nome do Empreendimento</h1>
                      <input className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' placeholder="Nome do empreendimento" />
                      <h1 className='text-sm text-gray-400'>https://atendepramim.ai/minhaloja </h1>

                      <h1 className='font-bold md:text-[15px] mt-2'>Email</h1>
                      <input placeholder='Email' className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />
                      <h1 className='text-xs text-red-300'>*O email é necessário para a recuperação de senha</h1>
                    </div>

                    <div className='flex flex-col'>
                      <h1 className='font-bold md:text-[15px] mt-2'>WhatsApp</h1>
                      <input className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' placeholder="(XX) X XXXX-XXXX" />
                      
                      <h1 className='font-bold md:text-[15px] mt-2'>Instagram</h1>
                      <input className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' placeholder="@contatoInsta" />

                      <h1 className='font-bold md:text-[15px] mt-2'>Facebook</h1>
                      <input className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' placeholder="Facebook" />
                    </div>

                    <div className='flex flex-col'>
                      <h1 className='font-bold md:text-[15px] mt-2'>LinkedIn</h1>
                      <input className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' placeholder="LinkedIn" />

                      <h1 className='font-bold text-[18px]'>Localização</h1>
                      <div className='border border-gray-400 rounded-lg w-32'>
                        <PiGlobeSimpleThin size={120} className='ml-1' color='gray' />
                      </div>
                    </div>
                  </div>

                  <div className='flex gap-4'>
                    <div className='flex flex-col w-full'>
                      <h1 className='font-bold text-xl md:text-[15px] mt-2'>Senha</h1>
                      <input type='password' className='p-1 pl-2 mt-1  w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />

                      <h1 className='font-bold text-xl md:text-[15px] mt-2'>Confirmação de senha</h1>
                      <input type='password' className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />
                    </div>

                    <button onClick={() => setEtapa(2)} className='bg-[#228b22] font-bold text-xl text-white w-[40%] h-14 p-2 mt-[10%] rounded-lg flex justify-center items-center'>
                      <PiArrowRightBold size={40} />
                    </button>
                  </div>
                </motion.div>
              )}

              {etapa === 2 && (
                <motion.div
                  key="etapa2"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <div className='flex gap-10'>
                    <div className='flex flex-col h-[550px] w-[400px] rounded-b-xl mt-2 shadow-dark-bottom-right '>
                      <div className='bg-cor_principal rounded-t-xl h-15 w-full'>
                        <div className='flex items-center'>
                          {fotoPerfil === null ?
                            <PiCircleFill color='white' size={90} className='ml-5' /> :
                            <img src={fotoPerfil} alt='Perfil' className='ml-5 h-[90px] rounded-full p-2' />
                          }
                          <h1 className='text-white text-2xl font-semibold'>{nomeAssistente}</h1>
                        </div>
                      </div>

                      <div className="flex-1 overflow-y-auto p-4 bg-fundo_chat h-full shadow-2xl">
                        {mensagens.map((mensagem, index) => (
                          <div key={index} className={`flex mb-2 ${mensagem.remetente === 'Você' ? 'justify-end' : 'justify-start'}`}>
                            <div className="flex items-start">
                              <div className={`p-2 rounded-lg text-sm text-white relative max-w-[80vw] ${mensagem.remetente === 'Assistente' ? 'bg-chat_cor_1 ml-2 text-cor_1' : 'bg-chat_cor_2 mr-2 text-left text-cor_2'}`} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                <div style={{ whiteSpace: 'pre-wrap' }}>{mensagem.texto}</div>
                                <div className={`${mensagem.remetente === 'Assistente' ? 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] bg_chat_tick rotate-[-40deg] bottom-[-5px] -ml-[15px]' : 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] bg_chat_tick_2 mt-2 right-0 rotate-[40deg] bottom-[-5px] -mr-[7px]'}`} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="p-3 flex flex-col w-full border-t border-gray-400 bg-white rounded-b-xl relative">
                        <div className='flex'>
                          <textarea type="text" placeholder="Digite uma mensagem..." className="w-full mr-10 p-2 pr-[34px] rounded-2xl bg-white border border-gray-400 resize-none focus:outline-none" rows={1} />
                          <PiPaperPlaneTilt size={40} color='white' className="absolute right-0 mr-2 bg-cor_principal p-[6px] rounded-full" />
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col items-center'>
                      <div className='mt-5'>
                        <FotoPerfil onImagemUpload={handleImagemUpload} imagem={fotoPerfil} />
                      </div>

                      <input onChange={(e) => setNomeAssistente(e.target.value)} placeholder='Nome do assistente' className='p-1 my-3 w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text shadow-xl' />

                      <Estilos largura={'350px'} />

                      <div className='flex w-full gap-5'>
                        <button onClick={() => setEtapa(1)} className='bg-[#228b22] font-bold text-xl text-white w-full h-14 p-2 mt-4 rounded-lg flex justify-center items-center'>
                          <PiArrowLeftBold size={40} />
                        </button>

                        <button onClick={() => setEtapa(3)} className='bg-[#228b22] font-bold text-xl text-white w-full h-14 p-2 mt-4 rounded-lg flex justify-center items-center'>
                          <PiArrowRightBold size={40} />
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}

              {etapa === 3 && (
                <motion.div
                  key="etapa3"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <div className='flex flex-col items-center w-[450px]'>
                    <div className='mt-5'>
                      <h1>
                        Para otimizar o assistente virtual, insira arquivos de texto ou uma seção de FAQ contendo informações relevantes a sua empresa.<br/>
                        Esses dados permitem que o assistente automaticamente construa respostas e rapidamente.
                      </h1>
                    </div>

                    <input
                      type="file"
                      accept="application/pdf"
                      ref={inputArquivosPDF}
                      multiple
                      onChange={handleFileUpload}
                      className="hidden"
                    />

                    <div className='flex items-center justify-start gap-5 w-full'>
                      <PiUpload
                        className='cursor-pointer' 
                        size={70}
                        onClick={() => inputArquivosPDF.current.click()}
                      />

                      <div className="mt-4 flex gap-5 w-full overflow-auto">
                        {arquivos.map((file, index) => (

                          <div key={index} className="flex mt-2">
                            
                            <div className='flex flex-col items-center'>
                              <div className='relative w-20'>
                                <PiFilePdf size={60}/>
                                <PiX size={20} onClick={() => removeFile(index)} color='red' className='absolute top-0 end-0 cursor-pointer'/>
                              </div>
                              <span className="text-sm text-gray-700">{file.name}</span>
                            </div>
                          
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className='flex w-full gap-5 mt-5'>
                        <button onClick={() => setEtapa(2)} className='bg-[#228b22] font-bold text-xl text-white w-full h-14 p-2 rounded-lg flex justify-center items-center'>
                            <PiArrowLeftBold size={40} />
                        </button>
                        <button onClick={() => navigate('/dashboard')} className='bg-[#228b22] font-bold text-xl text-white w-full p-2 rounded-lg justify-center items-center'>
                            Finalizar Cadastro
                        </button>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Cadastro;
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './index.css';

import Home from './root/paginas/homePage'
import Dashboard from './root/paginas/dashboard'

function App() {
  return (
    <main>
      <Routes>
        <Route >
          <Route path='/' element={<Home />} />
        </Route>

        <Route>
          <Route path='/dashboard' element={<Dashboard />} />
        </Route>
      </Routes>
    </main>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiPaperPlaneTilt, PiXBold } from 'react-icons/pi';
import { motion } from 'framer-motion';
import Chat from './ui/chat';
import ChatMobile from './ui/chat_mobile'
import Cadastro from './ui/cadastro';
import CadastroMobile from './ui/cadastro_mobile'
import './homePage.css';
import { isMobile } from 'react-device-detect';

function HomePage() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [showWidget, setShowWidget] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [showCadastroMobile, setShowCadastroMobile] = useState(false);
  const [showRecSenha, setShowRecSenha] = useState(false);

  const chatMessages = [
    "Olá Jose, o que vai ser?",
    "Aspirinas, voce tem?",
    "Aspirinas, voce tem?",
    "Custa R$5,60 a cartela, com a taxa de entrega fica R$10,60",
    "Estou aguardando.",
    "O entregador chega em 25 min.",
  ];

  useEffect(() => {
    let i = 0;
    const intervalId = setInterval(() => {
      if (i < chatMessages.length) {
        setMessages(prevMessages => [...prevMessages, chatMessages[i]]);
        i++;
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch('https://suapuno.app:8080/api-token-auth/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: "suapinho",
            password: "senhaFRACA"
          })
        });

        if (response.ok) {
          const api = await response.json();
          localStorage.setItem('token', api.token);
        } else {
          console.error('Api não retornou o ok:', response.status);
        }
      } catch (error) {
        console.error('Erro ao tentar obter token de chat generico:', error);
      }
    };

    fetchToken();
  }, []);

  if(isMobile){
    return (
      <div className='h-full'>
        {showCadastroMobile && 
          <CadastroMobile
            setShowCadastroMobile={setShowCadastroMobile}
          />
        }
        <header className='flex items-center h-[9vh] border-b-2' onClick={() => setShowWidget(false)}>
          <img src='/imagens/logo.png' className=' ml-6 h-10'/>
          <h1 className='ml-2 text-[4vh] font-bold'>
            AtendePraMim
          </h1>
        </header>

        <div className='flex h-[90%] rounded-lg w-full justify-center items-center'>
          <div className='relative overflow-hidden m-5 rounded-lg text-white'>
            <img 
              src='/imagens/img_homepage.jpg' 
              alt='img_homepage' 
              className='w-[800px] h-[500px] max-w-[800px] max-h-[500px] object-cover rounded-lg'
            />
            <div className='bg-[#0000006b] max-w-[800px] max-h-[500px] absolute inset-0 rounded-lg'>
              <div className='w-[90%]'>
                <h1 className='ml-5 mt-5 text-[24px] font-bold leading-tight'>
                  Seu Atendente Inteligente<br /> Personalizado para o Seu<br /> Negócio
                </h1>
                <h1 className='ml-5 mt-8 text-[18px] leading-tight'>
                  À medida que seu negócio cresce, a demanda por atendimento ao cliente também aumenta.<br /> Nosso atendente é a solução ideal para atender a um grande volume de solicitações, sem comprometer a qualidade do atendimento.
                </h1>
                <h1 className='ml-5 mt-8 text-[20px] leading-tight'>
                  Respostas precisas e personalizadas garantem mais satisfação e permitem que sua equipe seja mais produtiva.
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className='relative flex flex-col justify-center border-2 border-black shadow-2xl rounded-lg w-[90%] h-[460px] m-5'>
          {showWidget && (
            <motion.div
              initial={{ x: -20 }}
              animate={{ opacity: 1, x: 20 }}
              exit={{ x: 0 }}z
              transition={{ type: 'spring', stiffness: 80}}
              className='absolute z-20'
            >
              <ChatMobile setShowWidget={setShowWidget} />
            </motion.div>
          )}
          <h1 className='text-center -mb-2 text-[20px] md:text-[35px] font-bold'>
            Login
          </h1>
          <input placeholder='Usuário' className='mx-10 md:mx-8 mt-3 p-1 pl-2 bg-gray-100 placeholder-gray-600 border-2 border-gray-300 rounded-lg text' />
          <input type='password' placeholder='Senha' className='mx-10 md:mx-8 mt-5 p-1 pl-2 bg-gray-100 placeholder-gray-600 border-2 border-gray-300 rounded-lg text' />
          <button onClick={() => navigate('/dashboard')} className='mx-10 md:mx-8 mt-5 bg-[#228b22] p-2 rounded-lg text-white'> Entrar </button>
          <h1 onClick={() => {setShowRecSenha(true)}} className='text-center text-sm mt-4 text-[#228b22] cursor-pointer'>Esqueceu a senha?</h1>
          <h1 onClick={() => {setShowCadastroMobile(true)}} className='text-center text-sm my-4 text-[#228b22] cursor-pointer'>Criar conta</h1>
          <div className='w-96 -mt-5 mb-20'>
            <div className='w-full flex justify-center items-center mt-4'>
              <div className='border border-gray-400 px-4 py-3 rounded-full bg-gray-100 cursor-pointer' onClick={() => setShowWidget(true)}>
                <h1>Como criar uma conta?</h1>
              </div>
              <PiPaperPlaneTilt size={50} color='white' className='p-[8px] ml-2 bg-[#228b22] rounded-full cursor-pointer' onClick={() => setShowWidget(true)}/>
            </div>
            
          </div>
        </div>


      </div>
    )
  }
  
  return (
    <div className='h-full'>
      {showCadastro && 
        <Cadastro
          setShowCadastro={setShowCadastro}
        />
      }
      {showRecSenha && 
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div  onClick={() => {setShowRecSenha(false)}} className='fixed inset-0 bg-black bg-opacity-50 z-10'/>
            <div className="bg-white p-5 w-[25%] rounded-lg z-20">
              <div className='flex flex-col'>
                <div className='flex justify-between'>
                  <h1 className='text-[35px] md:text-[25px] font-bold'>Esqueceu a Senha?</h1>
                  <PiXBold onClick={() => {setShowRecSenha(false)}} size={40} className='md:m-0 m-2 cursor-pointer'/>
                </div>

                <h1 className='text-2xl md:text-lg mt-5'>Nos informe seu e-mail de usuário<br/> para que possamos ajudar a<br/> recuperar sua senha</h1>
                
                <h1 className='font-bold text-xl mt-5'>Email</h1>
                <input placeholder='Email' className='p-1 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />

                <button className='bg-[#228b22] font-bold text-xl text-white w-full p-2 mt-4 rounded-lg justify-center items-center' >
                  CONFIRMAR
                </button>

              </div>
            </div>
        </div>
      }
      <header className='flex items-center h-[9vh] border-b-2' onClick={() => setShowWidget(false)}>
        <img src='/imagens/logo.png' className=' ml-6 h-10'/>
        <h1 className='ml-2 text-[4vh] font-bold'>
          AtendePraMim
        </h1>
      </header>
      <div className='flex flex-row justify-around items-center'>
        <div className='relative m-10 mt-4 text-white w-[65%] rounded-xl' onClick={() => setShowWidget(false)}>
          <img src='/imagens/img_homepage.jpg' alt='img_homepage' className='w-full h-full rounded-3xl shadow-2xl' />
          <div className='bg-[#0000005b] absolute top-0 w-full h-full rounded-3xl'>
            <div className='w-[90%] md:w-[60%]'>
              <h1 className='ml-10 mt-10 text-[2vw] font-bold leading-tight'>
                Seu Atendente Inteligente,<br /> Personalizado para o Seu<br /> Negócio
              </h1>
              <h1 className='mt-8 ml-10 text-[1.5vw] leading-tight'>
                À medida que seu negócio cresce, a demanda<br /> por atendimento ao cliente também aumenta.<br /> Nosso atendente é a solução ideal para atender<br /> a um grande volume de solicitações, sem<br /> comprometer a qualidade do atendimento.
              </h1>
              <h1 className='mt-8 ml-10 text-[1.5vw] leading-tight'>
                Respostas precisas e personalizadas garantem<br /> mais satisfação e permitem que sua equipe<br /> seja mais produtiva.
              </h1>
            </div>
          </div>
          <div className='flex absolute top-0 end-0 w-full justify-end'>
            <div className='w-[40%] h-full border-[5px] m-2 md:m-5 rounded-[40px]'>
              <div className='bg-[#003366] rounded-t-[35px] h-20 sombra'></div>
              <div className='bg-[#ffffffcb] h-[60vh] rounded-b-[35px] overflow-y-hidden p-4 scrollbar-hidden'>
                {messages.filter(msg => msg).map((msg, index) => (
                  <div key={index} className={`w-full flex ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}>
                    <div className={`text-[1vw] fade-in px-5 py-3 md:py-2 mb-4 max-w-[90%] ${index % 2 === 0 ? 'bg-[#bfbebd] border border-[#a3a2a2] text-black font-[500] rounded-t-lg rounded-e-lg' : 'bg-[#5ca55d] border border-[#4c884d] text-white font-[500] rounded-t-lg rounded-s-lg'}`}>
                      {msg}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='relative flex flex-col justify-center mr-10 border-2 border-black shadow-2xl rounded-lg 2xl:w-[20%] w-[25%] h-[460px]'>
          {showWidget && (
            <motion.div
              initial={{ x: 5 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ x: 0 }}
              transition={{ type: 'spring', stiffness: 80}}
              className='absolute'
            >
              <Chat setShowWidget={setShowWidget} />
            </motion.div>
          )}
          <h1 className='text-center -mb-2 text-[20px] md:text-[35px] font-bold'>
            Login
          </h1>
          <input placeholder='Usuário' className='mx-10 md:mx-8 mt-3 p-1 pl-2 bg-gray-100 placeholder-gray-600 border-2 border-gray-300 rounded-lg text' />
          <input type='password' placeholder='Senha' className='mx-10 md:mx-8 mt-5 p-1 pl-2 bg-gray-100 placeholder-gray-600 border-2 border-gray-300 rounded-lg text' />
          <button onClick={() => navigate('/dashboard')} className='mx-10 md:mx-8 mt-5 bg-[#228b22] p-2 rounded-lg text-white'> Entrar </button>
          <h1 onClick={() => {setShowRecSenha(true)}} className='text-center text-sm mt-4 text-[#228b22] cursor-pointer'>Esqueceu a senha?</h1>
          <h1 onClick={() => {setShowCadastro(true)}} className='text-center text-sm mt-4 text-[#228b22] cursor-pointer'>Criar conta</h1>
          <div className='w-full flex justify-center items-center mt-4 md:text-[10px]'>
            <div className='border border-gray-400 md:text-[12px] px-4 py-3 rounded-full bg-gray-100 cursor-pointer' onClick={() => setShowWidget(true)}>
              <h1>Como criar uma conta?</h1>
            </div>
            <PiPaperPlaneTilt size={50} color='white' className='p-[8px] md:p-[10px] ml-2 bg-[#228b22] rounded-full cursor-pointer' onClick={() => setShowWidget(true)}/>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default HomePage;
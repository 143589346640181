import React from 'react';
import { isMobile } from 'react-device-detect';
import { FaSearch } from 'react-icons/fa';
import { PiArrowRight, PiPlusBold, PiTrash } from 'react-icons/pi';

const Entidades = () => {
  const loops = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  if(isMobile){
    return (
      <div className='w-full h-full overflow-hidden'>
        <div className='bg-white rounded-lg w-full h-full'>
          <div className='flex flex-col px-5 py-1 h-[100%]'>
            <h1 className='font-semibold text-[20px]'>Entidades</h1>
  
            <div className="flex flex-col w-full relative mt-1">
              <input
                id="termo-busca"
                className="border border-gray-400 bg-gray-100 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
                placeholder="Buscar"
              />
              <span className="absolute mt-[8px] ml-2 text-gray-400">
                <FaSearch size={20} />
              </span>
            </div>
  
            <div>
              <div className='flex gap-5 w-[100%] mt-1'>
                <div className='w-full'>
                  <h1 className='text-[15px]'>Nova entidade / tema</h1>
                  <input className="border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none" />
                </div>
                <div className='w-full'>
                  <h1 className='text-[15px]'>Nova entidade / contexto</h1>
                  <input className="border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none" />
                </div>
                
                <div className='flex items-end'>
                  <button className='bg-[#228b22] text-white w-full p-2 rounded-lg justify-center items-center'>
                    <PiPlusBold size={20}/>
                  </button>
                </div>
              
              </div>
            </div>
  
            <div className='overflow-auto h-full'>
                 { loops.map((index) => (
                    <div key={index} className='flex flex-col w-[98%] border border-gray-400 p-2 mt-2 rounded-md'>
                      <div className='flex justify-between items-center p-3'>
                        <div className='bg-[#337e71] px-2 py-1 text-2xl font-semibold text-white rounded'>
                          <h1 className='text-[15px]'>Entidade</h1>
                        </div>
                        <PiTrash size={30} color='red' className='cursor-pointer'/>
                      </div>
                      <div className='flex w-full justify-between'>
                        <h1 className='flex items-center text-[15px] ml-2 text-gray-500'>
                          Descrição da entidade
                        </h1>
                      </div>

                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='px-8 py-7 w-full h-[85vh]'>
      <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-full h-full'>
        <div className='flex flex-col px-5 py-3 h-[100%]'>
          <h1 className='font-semibold text-[32px]'>Entidades</h1>

          <div className="flex flex-col w-full relative mt-1">
            <input
              id="termo-busca"
              className="border border-gray-400 bg-gray-100 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
              placeholder="Buscar"
            />
            <span className="absolute mt-[8px] ml-2 text-gray-400">
              <FaSearch size={20} />
            </span>
          </div>

          <div>
            <div className='flex gap-5 w-[100%] mt-1'>
              <div className='w-full'>
                <h1>Nova entidade / tema</h1>
                <input className="border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none" />
              </div>
              <div className='w-full'>
                <h1>Nova entidade / contexto</h1>
                <input className="border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none" />
              </div>
              <div className='w-full'>
                <h1 className='text-white'>.</h1>
                <button className='bg-[#228b22] text-white w-full p-2 rounded justify-center items-center'>
                  Adicionar entidade
                </button>
              </div>
            </div>
          </div>

          <div className='overflow-auto h-full'>
               { loops.map((index) => (
                  <div key={index} className='flex w-full border border-gray-400 p-2 mt-2 rounded-md' >
                    <div className='flex items-center p-3'>
                      <div className='bg-[#337e71] px-4 py-2 text-2xl font-semibold text-white rounded'>
                        <h1>Entidade</h1>
                      </div>
                      <PiArrowRight className='ml-2' size={35}/>
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                    </div>
                    <div className='flex w-full justify-between'>
                      <h1 className='flex items-center text-[30px] ml-2 text-gray-500'>
                        Descrição da entidade
                      </h1>
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                    </div>
                    <div className='flex items-center mx-3 mr-10'>
                      <PiTrash size={50} color='red' className='cursor-pointer'/>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entidades;

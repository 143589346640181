import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { PiCircleFill } from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { isMobile } from 'react-device-detect';

const Conversas = () => {
  const loops = [
    { id: 1, alerta: true },
    { id: 2, alerta: false },
    { id: 3, alerta: true },
    { id: 4, alerta: false },
    { id: 5, alerta: true },
    { id: 6, alerta: false },
    { id: 7, alerta: true },
    { id: 8, alerta: false },
    { id: 9, alerta: true },
  ];
  const [conversaSelecionada, setConversaSelecionada] = useState(null);
  const [animacaoAtiva, setAnimacaoAtiva] = useState(false);
  const [tipoConversa, setTipoConversa] = useState(1)

  const handleTipoConversaChange = (tipo) => { // função pra controlar como as animações e os cards se comportam nas duas abas diferentes
    setTipoConversa(tipo);
    setConversaSelecionada(null);
    setAnimacaoAtiva(true);
  };

  if(isMobile){
    return (
      <div className='w-full h-full relative'>
        <div className='bg-white rounded-lg w-full h-full'>
          <div className='flex flex-col px-5 py-1 h-full'>
            <div className='absolute start-0 flex justify-around w-full shadow-dark-bottom-right'>
              <div className='mb-2 w-full relative'>
                <h1 onClick={() => handleTipoConversaChange(1)} className='font-semibold text-[16px] text-center'>
                  Conversas
                </h1>
                {tipoConversa === 1 && <div className='h-1 w-full bg-cor_principal absolute mt-1' />}
              </div>
  
              <div className='mb-2 w-full relative'>
                <h1 onClick={() => handleTipoConversaChange(2)} className='font-semibold text-[16px] text-center'>
                  Conversas com alerta
                </h1>
                {tipoConversa === 2 && <div className='h-1 w-full bg-cor_principal absolute mt-1' />}
              </div>
            </div>
  
            <div className="flex flex-col w-full relative mt-14">
              <input
                id="termo-busca"
                className="border border-gray-400 bg-gray-100 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
                placeholder="Buscar"
              />
              <span className="absolute mt-[8px] ml-2 text-gray-400">
                <FaSearch size={20} />
              </span>
            </div>
  
            <div className={`w-full overflow-auto h-full mt-1 ${animacaoAtiva ? 'overflow-hidden' : 'overflow-auto'}`}>
              <div className={`w-full h-[98%]`}>
                <AnimatePresence mode="wait">
                  {!conversaSelecionada ? (
                    <motion.div
                      className='flex gap-4'
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 50 }}
                      transition={{ duration: 0.5 }}
                      onAnimationStart={() => setAnimacaoAtiva(true)}
                      onAnimationComplete={() => setAnimacaoAtiva(false)}
                    >
                      {tipoConversa === 1 ? (
                        <div className='w-full'>
                          {loops.filter(item => !item.alerta).map(({ id }) => (
                            <motion.div
                              key={id}
                              onClick={() => setConversaSelecionada(id)}
                              className='flex justify-between w-full border border-gray-400 p-2 mt-2 rounded-md'
                              initial={{ opacity: 0, y: 50 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: 50 }}
                              transition={{ duration: 0.3 }}
                            >
                              <div className='flex'>
                                <PiCircleFill color='#482fdf' size={80} />
                                <div>
                                  <h1 className='flex items-center font-bold text-[30px] ml-2 mt-1'> Nome </h1>
                                  <h1 className='flex items-center font-semibold text-[20px] ml-2 -mt-2'> email </h1>
                                </div>
                              </div>
                              <div className='flex justify-center items-center'>
                                <MdOutlineKeyboardArrowDown size={50} />
                              </div>
                            </motion.div>
                          ))}
                        </div>
                      ) : (
                        <div className='w-full'>
                          {loops.filter(item => item.alerta).map(({ id }) => (
                            <motion.div
                              key={id}
                              onClick={() => setConversaSelecionada(id)}
                              className='flex justify-between w-full border border-gray-400 p-2 mt-2 rounded-md'
                              initial={{ opacity: 0, y: 50 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: 50 }}
                              transition={{ duration: 0.3 }}
                            >
                              <div className='flex'>
                                <PiCircleFill color='#482fdf' size={80} />
                                <div>
                                  <h1 className='flex items-center font-bold text-[30px] ml-2 mt-1'> Nome </h1>
                                  <h1 className='flex items-center font-semibold text-[20px] ml-2 -mt-2'> email </h1>
                                </div>
                              </div>
                              <div className='flex justify-center items-center'>
                                <MdOutlineKeyboardArrowDown size={50} />
                              </div>
                            </motion.div>
                          ))}
                        </div>
                      )}
                    </motion.div>
                  ) : (
                    <motion.div
                      key="detail-view"
                      className='h-[98%] w-full '
                      initial={{ height: 0, opacity: 1 }}
                      animate={{ height: '98%', opacity: 1 }}
                      exit={{ height: 0, opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      onAnimationStart={() => setAnimacaoAtiva(true)}
                      onAnimationComplete={() => setAnimacaoAtiva(false)}
                    >
                      <motion.div
                        className='flex flex-col relative h-[95%] w-full border border-gray-400 p-2 mt-2 rounded-md'
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <div className='absolute border-[1px] mt-[95px] -ml-2 w-full border-gray-500 rounded-md' />
                        <div onClick={() => setConversaSelecionada(null)} className='flex flex-row justify-between w-full'>
                          <div className='flex'>
                            <PiCircleFill color='#482fdf' size={80} className='ml-5' />
                            <div>
                              <h1 className='flex items-center font-bold text-[30px] ml-2 mt-1'> Nome </h1>
                              <h1 className='flex items-center font-semibold text-[20px] ml-2 -mt-2'> email </h1>
                            </div>
                          </div>
                          <div className='flex justify-center items-center'>
                            <motion.div
                              style={{ transformOrigin: "center" }}
                              animate={{ rotate: -180 }}
                              exit={{ rotate: 0, opacity: 0 }}
                              transition={{ duration: 0.5 }}
                            >
                              <MdOutlineKeyboardArrowDown size={50} />
                            </motion.div>
                          </div>
                        </div>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className='px-8 py-7 w-full h-full'>
      <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-full h-full'>
        <div className='flex flex-col px-5 py-3 h-[100%]'>
          <h1 className='font-semibold text-[20px]'>Conversas</h1>

          <div className="flex flex-col w-full relative mt-1">
            <input
              id="termo-busca"
              className="border border-gray-400 bg-gray-100 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
              placeholder="Buscar"
            />
            <span className="absolute mt-[8px] ml-2 text-gray-400">
              <FaSearch size={20} />
            </span>
          </div>

          <div className={`w-full overflow-auto h-full mt-4 ${animacaoAtiva ? 'overflow-hidden' : 'overflow-auto'}`}>
            <div className={`w-full h-[98%]`}>
              <AnimatePresence mode="wait">
                {!conversaSelecionada ? (
                  <motion.div
                    className='flex gap-4'
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    transition={{ duration: 0.5 }}
                    onAnimationStart={() => setAnimacaoAtiva(true)}
                    onAnimationComplete={() => setAnimacaoAtiva(false)}
                  >
                    <div className='w-full '>
                    <h1 className='font-bold text-[32px]'>Conversas</h1>
                      {loops.filter(item => !item.alerta).map(({ id }) => (
                        <motion.div
                          key={id}
                          onClick={() => setConversaSelecionada(id)}
                          className='flex justify-between w-full border border-gray-400 p-2 mt-2 rounded-md'
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 50 }}
                          transition={{ duration: 0.3 }}
                        >
                          <div className='flex'>
                            <PiCircleFill color='#482fdf' size={80} className='ml-5' />
                            <div>
                              <h1 className='flex items-center font-bold text-[30px] ml-2 mt-1'> Nome </h1>
                              <h1 className='flex items-center font-semibold text-[20px] ml-2 -mt-2'> email </h1>
                            </div>
                          </div>
                          <div className='flex justify-center items-center'>
                            <MdOutlineKeyboardArrowDown size={50} />
                          </div>
                        </motion.div>
                      ))}
                    </div>
                    <div className='w-full'>
                    <h1 className='font-bold text-[32px]'>Conversas com alerta</h1>
                      {loops.filter(item => item.alerta).map(({ id }) => (
                        <motion.div
                          key={id}
                          onClick={() => setConversaSelecionada(id)}
                          className='flex justify-between w-full border border-gray-400 p-2 mt-2 rounded-md'
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 50 }}
                          transition={{ duration: 0.3 }}
                        >
                          <div className='flex'>
                            <PiCircleFill color='blue' size={80} className='ml-5' />
                            <div>
                              <h1 className='flex items-center font-bold text-[30px] ml-2 mt-1'> Nome </h1>
                              <h1 className='flex items-center font-semibold text-[20px] ml-2 -mt-2'> email </h1>
                            </div>
                          </div>
                          <div className='flex justify-center items-center'>
                            <MdOutlineKeyboardArrowDown size={50} />
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    key="detail-view"
                    className='h-[98%] w-full '
                    initial={{ height: 0, opacity: 1 }}
                    animate={{ height: '98%', opacity: 1 }}
                    exit={{ height: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    onAnimationStart={() => setAnimacaoAtiva(true)}
                    onAnimationComplete={() => setAnimacaoAtiva(false)}
                  >
                    <motion.div
                      className='flex flex-col relative h-[95%] w-full border border-gray-400 p-2 mt-2 rounded-md'
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className='absolute border-[1px] mt-[95px] -ml-2 w-full border-gray-500 rounded-md' />
                      <div onClick={() => setConversaSelecionada(null)} className='flex flex-row justify-between w-full'>
                        <div className='flex'>
                          <PiCircleFill color='#482fdf' size={80} className='ml-5' />
                          <div>
                            <h1 className='flex items-center font-bold text-[30px] ml-2 mt-1'> Nome </h1>
                            <h1 className='flex items-center font-semibold text-[20px] ml-2 -mt-2'> email </h1>
                          </div>
                        </div>
                        <div className='flex justify-center items-center'>
                          <motion.div
                            style={{ transformOrigin: "center" }}
                            animate={{ rotate: -180 }}
                            exit={{ rotate: 0, opacity: 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            <MdOutlineKeyboardArrowDown size={50} />
                          </motion.div>
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversas;
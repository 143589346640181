import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiCircleFill } from 'react-icons/pi';
import { RxExit } from 'react-icons/rx';
import { motion, AnimatePresence } from 'framer-motion';
import QRCode from 'react-qr-code';

function Header({ pagina, setPagina }) {
  const [modalPerfil, setModalPerfil] = useState(false);
  const modalRef = useRef(null); 
  const navigate = useNavigate();

  const mudarPagina = (pagina) => {
    setPagina(pagina);
    setModalPerfil(false);
  };

  // fechar o modal quando clicar fora dele
  useEffect(() => {
    const handleFechar = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalPerfil(false);
      }
    };

    if (modalPerfil) {
      document.addEventListener('mousedown', handleFechar);
    }
    return () => {
      document.removeEventListener('mousedown', handleFechar);
    };
  }, [modalPerfil]);

  return (
    <div className='bg-cor_principal p-2 relative'>
      <div className='flex justify-end ml-5'>
        <div className='flex'>
          <div className='flex items-center justify-center text-white text-[25px] ml-2'>
            Minha_Empresa
          </div>
          <div onClick={() => { setModalPerfil(!modalPerfil) }} className='cursor-pointer'>
            <PiCircleFill color='white' size={90} />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {modalPerfil && (
          <motion.div
            ref={modalRef}
            className='absolute right-0 top-0 h-screen w-64 flex flex-col bg-white shadow-lg py-6 px-6 items-center text-[16px] gap-6 rounded-md z-50'
            initial={{ opacity: 0, x: 30 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 30 }}
            transition={{ duration: 0.3 }}
          >
            <div className='flex flex-col items-center gap-4'>
              <PiCircleFill color='black' size={100} />
              <h1 onClick={() => mudarPagina(1)} className={`${pagina === 1 ? 'underline font-bold' : ''} cursor-pointer`}>
                Início
              </h1>
              <h1 onClick={() => mudarPagina(2)} className={`${pagina === 2 ? 'underline font-bold' : ''} cursor-pointer`}>
                Editar perfil
              </h1>
            </div>
            <div onClick={() => navigate('/')} className='flex items-center cursor-pointer gap-2 text-red-600'>
              <h1>Sair</h1>
              <RxExit size={25} color='red' />
            </div>
            <div className='flex flex-col justify-center items-center'>
              <QRCode value="https://atendepramim.com" size={120} />
              <p className='text-sm mt-2 text-center text-gray-600'>Escaneie para acessar seu assistente</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Header;
import React, { useState } from 'react';
import { FaBars, FaSearch } from 'react-icons/fa';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import { PiPlusBold, PiTrash, PiXBold } from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import { isMobile } from 'react-device-detect';

const Contextos = () => {
  const initialContextos = [
    { id: 1, pergunta: 'Pergunta 1', resposta: 'Resposta 1', visibilidade: true },
    { id: 2, pergunta: 'Pergunta 2', resposta: 'Resposta 2', visibilidade: true },
    { id: 3, pergunta: 'Pergunta 3', resposta: 'Resposta 3', visibilidade: false },
    { id: 4, pergunta: 'Pergunta 4', resposta: 'Resposta 4', visibilidade: true },
    { id: 5, pergunta: 'Pergunta 5', resposta: 'Resposta 5', visibilidade: false },
  ];

  const [contextos, setContextos] = useState(initialContextos);
  const [contextoSelecionado, setContextoSelecionado] = useState(null);
  const [novaPergunta, setNovaPergunta] = useState('');
  const [novaResposta, setNovaResposta] = useState('');

  const toggleVisibilidade = (id) => {
    setContextos((prevContextos) =>
      prevContextos.map((contexto) =>
        contexto.id === id
          ? { ...contexto, visibilidade: !contexto.visibilidade }
          : contexto
      )
    );
  };

  const adicionarContexto = () => {
    if (novaPergunta && novaResposta) {
      const novoContexto = {
        id: contextos.length + 1, // novo ID
        pergunta: novaPergunta,
        resposta: novaResposta,
        visibilidade: true,
      };

      setContextos((prevContextos) => [...prevContextos, novoContexto]);
      setNovaPergunta('');
      setNovaResposta('');
    }
  };

  if(isMobile){
    return (
      <div className='w-full h-full'>
        <div className='bg-white rounded-lg w-full h-full '>
          <div className='flex flex-col px-5 py-1 h-full'>
            <h1 className='font-semibold text-[20px]'>Contextos</h1>
  
            <div className="flex flex-col w-full relative mt-1">
              <input
                id="termo-busca"
                className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-10 py-1 h-9 resize-none focus:outline-none"
                placeholder="Buscar"
              />
              <span className="absolute mt-[8px] ml-2 text-gray-400">
                <FaSearch size={20} />
              </span>
            </div>
  
            <div>
              <div className='flex gap-5 w-[100%] mt-1'>
                <div className='w-full'>
                  <h1 className='text-[15px]'>Nova pergunta / tema</h1>
                  <input
                    value={novaPergunta}
                    onChange={(e) => setNovaPergunta(e.target.value)}
                    className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
                  />
                </div>
                <div className='w-full'>
                  <h1 className='text-[15px]'>Nova resposta / contexto</h1>
                  <input
                    value={novaResposta}
                    onChange={(e) => setNovaResposta(e.target.value)}
                    className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
                  />
                </div>
                <div className='flex items-end'>
                  <button
                    onClick={adicionarContexto}
                    className='bg-[#228b22] text-white text-sm w-full p-2 rounded-lg justify-center items-center'
                  >
                    <PiPlusBold size={20}/>
                  </button>
                </div>
              </div>
            </div>
  
            <div className='overflow-auto h-full'>
              <AnimatePresence>
                {!contextoSelecionado ? (
                  contextos.map((contexto) => (
                    <div key={contexto.id} className='flex w-[98%] border border-gray-400 p-2 mt-2 rounded-md'>
                      <div className='flex items-center'>
                        <FaBars onClick={() => setContextoSelecionado(contexto)} size={40} className='p-1 ml-4 cursor-pointer' />
                        <div className='border-2 border-gray-300 rounded-md mx-2 h-full'/>
                      </div>
                      <div className='flex w-full justify-between'>
                        <h1 className='flex items-center text-[15px] ml-2 text-gray-500'>
                          {contexto.resposta}
                        </h1>
                        <div className='border-2 border-gray-300 rounded-md mx-2'/>
                      </div>
                      <div className='flex flex-col gap-2 mx-3 cursor-pointer'>
                        {contexto.visibilidade ? (
                          <LuEye size={30} color='green' onClick={() => toggleVisibilidade(contexto.id)} />
                        ) : (
                          <LuEyeOff size={30} color='gray' onClick={() => toggleVisibilidade(contexto.id)} />
                        )}
                        <PiTrash size={30} color='red' />
                      </div>
                    </div>
                  ))
                ) : (
                  <motion.div
                    key="painel"
                    className='h-[98%] relative'
                    initial={{ height: 0, opacity: 1 }}
                    animate={{ height: '98%', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className='absolute border-[1px] mt-[80px] w-[100%] border-gray-500 rounded-md' />
                    <div className='flex flex-col h-[98%] w-full border border-gray-400 p-2 mt-2 rounded-md'>
                      <div className='flex'>
                        <div className='flex items-center'>
                          <PiXBold onClick={() => setContextoSelecionado(null)} size={50} className='p-1 ml-2 cursor-pointer' />
                          <div className='border-2 border-gray-300 rounded-md mx-2 h-full'/>
                        </div>
                        <div className='flex w-full justify-between'>
                          <h1 className='flex items-center text-[15px] ml-2 text-gray-500'>
                            {contextoSelecionado.resposta}
                          </h1>
                          <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                        </div>
 
                        <div className='flex flex-col gap-2 mx-3 cursor-pointer'>
                          {contextoSelecionado.visibilidade ? (
                            <LuEye size={30} color='green' onClick={() => toggleVisibilidade(contextoSelecionado.id)} />
                          ) : (
                            <LuEyeOff size={30} color='gray' onClick={() => toggleVisibilidade(contextoSelecionado.id)} />
                          )}
                          <PiTrash size={30} color='red' />
                        </div>
                      </div>
  
                      <div>
                        <div className='flex  gap-5 w-[100%] mt-3'>
                          <div className='w-[80%]'>
                            <h1>Nova pergunta / tema similar</h1>
                            <input className="border mt-1 border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none" placeholder='Nova pergunta / tema similar' />
                          </div>
                          <div className='flex items-end'>
                            <button
                              className='bg-[#228b22] text-white text-sm w-full p-2 rounded-lg justify-center items-center'
                            >
                              <PiPlusBold size={20}/>
                            </button>
                          </div>

                        </div>
                      </div>
  
                      <div className='flex gap-5 h-full mt-4'>
                        <div className='w-full h-full flex flex-col'>
                          <div className='w-full h-full border border-gray-300 rounded-md p-2 resize-none'></div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='px-8 py-7 w-full h-[80vh]'>
      <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-full h-full'>
        <div className='flex flex-col px-5 py-3 h-[100%]'>
          <h1 className='font-semibold text-[32px]'>Contextos</h1>

          <div className="flex flex-col w-full relative mt-1">
            <input
              id="termo-busca"
              className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-10 py-1 h-9 resize-none focus:outline-none"
              placeholder="Buscar"
            />
            <span className="absolute mt-[8px] ml-2 text-gray-400">
              <FaSearch size={20} />
            </span>
          </div>

          <div>
            <div className='flex gap-5 w-[100%] mt-1'>
              <div className='w-full'>
                <h1>Nova pergunta / tema</h1>
                <input
                  value={novaPergunta}
                  onChange={(e) => setNovaPergunta(e.target.value)}
                  className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
                />
              </div>
              <div className='w-full'>
                <h1>Nova resposta / contexto</h1>
                <input
                  value={novaResposta}
                  onChange={(e) => setNovaResposta(e.target.value)}
                  className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
                />
              </div>
              <div className='w-full'>
                <h1 className='text-white'>.</h1>
                <button
                  onClick={adicionarContexto}
                  className='bg-[#228b22] text-white w-full p-2 rounded-lg justify-center items-center'
                >
                  Adicionar contexto
                </button>
              </div>
            </div>
          </div>

          <div className='overflow-auto h-full'>
            <AnimatePresence>
              {!contextoSelecionado ? (
                contextos.map((contexto) => (
                  <div key={contexto.id} className='flex w-full border border-gray-400 p-2 mt-2 rounded-md'>
                    <div className='flex'>
                      <FaBars onClick={() => setContextoSelecionado(contexto)} size={40} className='p-1 ml-4 mt-[6px] cursor-pointer' />
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                    </div>
                    <div className='flex w-full justify-between'>
                      <h1 className='flex items-center text-[30px] ml-2 text-gray-500'>
                        {contexto.pergunta}
                      </h1>
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                    </div>
                    <div className='flex w-full justify-between'>
                      <h1 className='flex items-center text-[30px] ml-2 text-gray-500'>
                        {contexto.resposta}
                      </h1>
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                    </div>
                    <div className='flex gap-2 mx-3 cursor-pointer'>
                      {contexto.visibilidade ? (
                        <LuEye size={50} color='green' onClick={() => toggleVisibilidade(contexto.id)} />
                      ) : (
                        <LuEyeOff size={50} color='gray' onClick={() => toggleVisibilidade(contexto.id)} />
                      )}
                      <PiTrash size={50} color='red' />
                    </div>
                  </div>
                ))
              ) : (
                <motion.div
                  key="painel"
                  className='h-[98%] relative'
                  initial={{ height: 0, opacity: 1 }}
                  animate={{ height: '98%', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className='absolute border-[1px] mt-[70px] w-[100%] border-gray-500 rounded-md' />
                  <div className='flex flex-col h-[98%] w-full border border-gray-400 p-2 mt-2 rounded-md'>
                    <div className='flex'>
                      <div className='flex'>
                        <PiXBold onClick={() => setContextoSelecionado(null)} size={50} className='p-1 ml-2 mt-[6px] cursor-pointer' />
                        <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                      </div>
                      <div className='flex w-full justify-between'>
                        <h1 className='flex items-center text-[30px] ml-2 text-gray-500'>
                          {contextoSelecionado.pergunta}
                        </h1>
                        <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                      </div>
                      <div className='flex w-full justify-between'>
                        <h1 className='flex items-center text-[30px] ml-2 text-gray-500'>
                        {contextoSelecionado.resposta}
                        </h1>
                        <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                      </div>
                      <div className='flex gap-2 mx-3 cursor-pointer'>
                        <LuEye size={50} color='green' />
                        <PiTrash size={50} color='red' />
                      </div>
                    </div>

                    <div>
                      <div className='flex gap-5 w-[100%] mt-3'>
                        <div className='w-[80%]'>
                          <h1>Nova pergunta / tema similar</h1>
                          <input className="border mt-1 border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none" placeholder='Nova pergunta / tema similar' />
                        </div>
                        <div className='w-[20%]'>
                          <h1 className='text-white'>.</h1>
                          <button className='bg-[#228b22] text-white w-full p-2 rounded-lg justify-center items-center'>
                            Adicionar contexto
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className='flex gap-5 h-full mt-4'>
                      <div className='w-full h-full flex flex-col'>
                        <h1 className='text-xl mb-2 -mt-2'>Pergunta/tema</h1>
                        <div className='w-full h-full border border-gray-300 rounded-md p-2 resize-none'></div>
                      </div>
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                      <textarea className='w-full h-full border border-gray-300 rounded-md p-2 resize-none' placeholder={'Pergunta/Tema'} />
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contextos;
import React, { useState } from 'react';
import { PiGlobeSimpleThin } from 'react-icons/pi';
import Estilos from '../ui/estilos';
import FotoPerfil from '../ui/fotoPerfil';
import { isMobile } from 'react-device-detect';

const Perfil = () => {
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [showDadosConta, setShowDadosConta] = useState(false);
  const [showDadosAssistente, setShowDadosAssistente] = useState(false);
  const [showRedesRelationadas, setShowRedesRelationadas] = useState(false);

  const handleImagemUpload = (imagemCortada) => {
    setFotoPerfil(imagemCortada);
  };

  const closeModal = () => {
    setShowDadosConta(false);
    setShowDadosAssistente(false);
    setShowRedesRelationadas(false);
  };

  if (isMobile) {
    return (
      <div className='w-full h-full'>
        <div className='flex flex-col'>
          <div className='flex flex-col w-full'>
            <div
              className='bg-white rounded-lg my-2 py-2 text-center text-lg font-semibold shadow-dark-bottom-right w-full h-full cursor-pointer'
              onClick={() => setShowDadosConta(true)}
            >
              Dados da Conta
            </div>
            <div
              className='bg-white rounded-lg my-1 py-2 text-center text-lg font-semibold shadow-dark-bottom-right w-full h-full cursor-pointer'
              onClick={() => setShowDadosAssistente(true)}
            >
              Dados assistente
            </div>
            <div
              className='bg-white rounded-lg mt-2 mb-3 py-2 text-center text-lg font-semibold shadow-dark-bottom-right w-full h-full cursor-pointer'
              onClick={() => setShowRedesRelationadas(true)}
            >
              Redes relationadas
            </div>
          </div>

          <div className='flex flex-row items-center justify-center'>
            <Estilos largura={'350px'} />
          </div>

          <div className='mt-2 p-3 px-5 text-center rounded-xl text-white bg-[#228b22] shadow-dark-bottom-right'>
            <h1 className='text-2xl font-semibold'>Salvar</h1>
          </div>
        </div>

        {showDadosConta && (
          <div className='fixed inset-0 z-20 flex items-center justify-center'>
            <div onClick={closeModal}  className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'/>
            <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[90%] z-50'>
              <div className='flex px-8 py-6 h-full'>
                <div className='flex flex-col h-full w-full ml-5'>
                  <div className='flex items-center justify-center'>
                    <FotoPerfil onImagemUpload={handleImagemUpload} />
                  </div>

                  <h1 className='font-bold text-[18px]'>Nome</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-[100%] pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='Nome do empreendimento'
                  />
                  <h1 className='text-sm text-gray-400'>
                    Https://atendepramim.ai/minhaloja{' '}
                  </h1>

                  <div className='flex'>
                    <div className='flex flex-col w-full'>
                      <h1 className='font-bold text-[18px] mt-2'>Email</h1>
                      <input
                        className='border border-gray-400 bg-gray-100 rounded-md w-[95%] pl-2 py-1 h-9 resize-none focus:outline-none'
                        placeholder='Email'
                      />
                      <h1 className='text-xs text-red-300'>
                        *O email é necessário para a recuperação de senha
                      </h1>
                    </div>
                  </div>

                  <div>
                    <h1 className='font-bold text-[18px] mt-3'>Localização</h1>
                    <div className='flex w-[130px] border border-gray-400 rounded-lg'>
                      <PiGlobeSimpleThin
                        size={120}
                        className='ml-1'
                        color='gray'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showDadosAssistente && (
          <div className='fixed inset-0 z-20 flex items-center justify-center'>
            <div onClick={closeModal}  className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'/>
            <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[90%] z-50'>
              <div className='flex items-center justify-center mt-4'>
                <FotoPerfil onImagemUpload={handleImagemUpload} />
              </div>
              <div className='flex px-8 pb-6 h-full'>
                <div className='flex flex-col h-full w-full ml-5'>
                  <h1 className='font-bold text-[18px]'>Nome</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-[100%] pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='Nome do assistente'
                  />

                </div>
              </div>
            </div>
          </div>
        )}

        {showRedesRelationadas && (
          <div className='fixed inset-0 z-20 flex items-center justify-center'>
            <div onClick={closeModal}  className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'/>
            <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[90%] z-50'>
              <div className='flex flex-col justify-center items-center my-6 px-5 py-3 h-[100%] mx-10'>
                <h1 className='font-bold text-[20px]'>
                  Contatos e Redes Sociais
                </h1>

                <div className='flex flex-col h-full w-full mt-[10%]'>
                  <h1 className='font-bold text-[15px]'>WhatsApp</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='(XX) X XXXX-XXXX'
                  />

                  <h1 className='font-bold text-[15px] mt-3'>Instagram</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='@contatoinsta'
                  />

                  <h1 className='font-bold text-[15px] mt-3'>Facebook</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full mb-8 pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='Facebook'
                  />
                </div>
              </div>
            </div>
        </div>
        )}
      </div>
    );
  }

  return (
    <div className='px-8 py-7 -mt-4 w-full h-full'>
      <div className='flex flex-col'>
        <div className='flex flex-row gap-28 mx-20'>
          <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[750px] h-full'>
            <div className='flex px-8 py-6 h-[100%]'>
              <div className='flex flex-col'>
                <h1 className='font-bold text-[22px]'>Editar Perfil</h1>
                <h1 className='font-bold text-[15px] mt-3 mb-2'>Logo</h1>
                <FotoPerfil onImagemUpload={handleImagemUpload} />
              </div>

              <div className='flex flex-col h-full w-full mt-[10%] ml-5'>
                <h1 className='font-bold text-[18px]'>Nome</h1>
                <input
                  className='border border-gray-400 bg-gray-100 rounded-md w-[100%] pl-2 py-1 h-9 resize-none focus:outline-none'
                  placeholder='Nome do empreendimento'
                />
                <h1 className='text-sm text-gray-400'>
                  Https://atendepramim.ai/minhaloja{' '}
                </h1>

                <div className='flex'>
                  <div className='flex flex-col w-full'>
                    <h1 className='font-bold text-[18px] mt-5'>Email</h1>
                    <input
  
                      className='border border-gray-400 bg-gray-100 rounded-md w-[95%] pl-2 py-1 h-9 resize-none focus:outline-none'
                      placeholder='Email'
                    />
                    <h1 className='text-xs text-red-300'>
                      *O email é necessário para a recuperação de senha
                    </h1>
                  </div>

                  <div className='w-[40%] -mb-8 '>
                    <h1 className='font-bold text-[18px]'>Localização</h1>
                    <div className='border border-gray-400 w-full rounded-lg'>
                      <PiGlobeSimpleThin
                        size={120}
                        className='ml-1'
                        color='gray'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[350px] h-full'>
            <div className='flex flex-col justify-center items-center my-6 px-5 py-3 h-[100%] mx-10'>
              <h1 className='font-bold text-[22px] md:-mx-7'>
                Contatos e Redes Sociais
              </h1>

              <div className='flex flex-col h-full w-full mt-[10%]'>
                <h1 className='font-bold text-[15px]'>WhatsApp</h1>
                <input
                  className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                  placeholder='(XX) X XXXX-XXXX'
                />

                <h1 className='font-bold text-[15px] mt-3'>Instagram</h1>
                <input
                  className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                  placeholder='@contatoinsta'
                />

                <h1 className='font-bold text-[15px] mt-3'>Facebook</h1>
                <input
                  className='border border-gray-400 bg-gray-100 rounded-md w-full mb-8 pl-2 py-1 h-9 resize-none focus:outline-none'
                  placeholder='Facebook'
                />
              </div>
            </div>
          </div>
        </div>

        {/*linha 2 */}

        <div className='flex flex-row gap-16 ml-20 2xl:mt-8'>
          <Estilos largura={'550px'} />

          <div className='bg-white rounded-lg border border-gray-300 mt-7 shadow-dark-bottom-right w-[200px] h-[140px]'>
            <div className='flex flex-col px-5 mx-3 py-3 h-[100%]'>
              <h1 className='font-semibold text-[20px] mt-5 text-red-500'>
                Sair
              </h1>
              <h1 className='font-semibold text-[20px] mt-3'>Mudar senha</h1>
            </div>
          </div>

          <div className='mt-28 p-3 px-5 rounded-xl text-white bg-[#228b22] shadow-dark-bottom-right'>
            <h1 className='text-2xl font-semibold'>Salvar</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Perfil;

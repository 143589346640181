import React from 'react';
import { GrTextAlignFull } from 'react-icons/gr';
import { PiChatsDuotone, PiCurrencyDollarSimple } from 'react-icons/pi';
import { RiSparkling2Line } from 'react-icons/ri';

function SideBar({selecionado, setSelecionado}) {
  return (
    <div className='fixed bottom-0 bg-white border border-t-gray-300 w-full shadow-2xl z-10'>
        <div className='flex justify-around cursor-pointer'>
            <div onClick={() => setSelecionado(1)} className={`w-full border-gray-500 ${selecionado === 1 ? 'bg-[#d6d6d6]' : ''}`}>
                <div className='flex justify-around my-2'>
                    <PiChatsDuotone size={30} color='gray'/>
                </div>
            </div>
            <div onClick={() => setSelecionado(2)} className={`w-full border-gray-500 ${selecionado === 2 ? 'bg-[#d6d6d6]' : ''}`}>
                <div className='flex justify-around my-2'>
                    <GrTextAlignFull size={30} color='gray'/>
                </div>
            </div>
            <div onClick={() => setSelecionado(3)} className={`w-full border-gray-500 ${selecionado === 3 ? 'bg-[#d6d6d6]' : ''}`}>
                <div className='flex justify-around my-2'>
                    <PiCurrencyDollarSimple size={30} color='gray'/>
                </div>
            </div>
            <div onClick={() => setSelecionado(4)} className={`w-full border-gray-500 ${selecionado === 4 ? 'bg-[#d6d6d6]' : ''}`}>
                <div className='flex justify-around my-2'>
                    <RiSparkling2Line size={30} color='gray'/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default SideBar;
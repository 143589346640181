import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiArrowLeftBold, PiArrowRightBold, PiXBold } from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import FotoPerfil from './fotoPerfil';
import Estilos from './estilos';

function CadastroMobile({ setShowCadastroMobile }) {
  const navigate = useNavigate();
  const [etapa, setEtapa] = useState(1);
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [arquivos, setArquivos] = useState([]);
  const [nomeAssistente, setNomeAssistente] = useState('Nome_Do_Assistente')

  const handleImagemUpload = (imagemCortada) => {
    setFotoPerfil(imagemCortada);
  };

  const pageVariants = {
    initial: { opacity: 0, x: 20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 40 },
  };

  return (
    <div className='h-full'>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div onClick={() => { setShowCadastroMobile(false) }} className='fixed inset-0 bg-black bg-opacity-50 z-10' />
        <div className="bg-white p-5 rounded-lg z-20">
          <div className='flex flex-col'>
            <div className='flex justify-between'>
              <h1 className='text-[25px] font-bold'>Criar Conta</h1>
              <PiXBold onClick={() => { setShowCadastroMobile(false) }} size={30} className='m-2 cursor-pointer' />
            </div>

            <AnimatePresence mode="wait">
              {etapa === 1 && (
                <motion.div
                  key="etapa1"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <h1 className='font-boldmt-2'>Nome de Usuário</h1>
                  <input placeholder='Nome de Usuário' className='p-1 mt-1 w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />
                  <h1 className='text-sm text-gray-400'>Nome para login</h1>

                    <div className='flex gap-10'>
                        <div className='flex flex-col'>
                        <h1 className='font-bold mt-2'>Nome do Empreendimento</h1>
                        <input className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' placeholder="Nome do empreendimento" />
                        <h1 className='text-sm text-gray-400'>https://atendepramim.ai/minhaloja </h1>

                        <h1 className='font-bold mt-2'>Email</h1>
                        <input placeholder='Email' className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />
                        <h1 className='text-xs text-red-300'>*O email é necessário para a recuperação de senha</h1>
                        </div>


                    </div>

                    <div className='flex gap-4'>
                        <div className='flex flex-col w-full'>
                        <h1 className='font-bold mt-2'>Senha</h1>
                        <input type='password' className='p-1 pl-2 mt-1  w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />

                        <h1 className='font-bold mt-2'>Confirmação de senha</h1>
                        <input type='password' className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' />
                        </div>

                    </div>

                    <div className='flex justify-end'>
                        <button onClick={() => setEtapa(2)} className='bg-[#228b22] font-bold text-xl text-white w-[40%] h-10 p-2 mt-2 rounded-lg flex justify-center items-center'>
                            <PiArrowRightBold size={20} />
                        </button>
                    </div>
                </motion.div>
              )}

              {etapa === 2 && (
                <motion.div
                  key="etapa2"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <div className='flex gap-10'>
                    

                    <div className='flex flex-col items-center'>
                      <div className='mt-5 mx-10'>
                        <FotoPerfil onImagemUpload={handleImagemUpload} imagem={fotoPerfil} />
                      </div>

                      <input onChange={(e) => setNomeAssistente(e.target.value)} placeholder='Nome do assistente' className='p-1 my-3 w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text shadow-xl' />

                      <Estilos largura={'300px'} />

                      <div className='flex justify-between w-full gap-5'>
                        <button onClick={() => setEtapa(1)} className='bg-[#228b22] font-bold text-xl text-white w-[40%] h-10 p-2 mt-2 rounded-lg flex justify-center items-center'>
                          <PiArrowLeftBold size={20} />
                        </button>
                        <button onClick={() => setShowCadastroMobile(false)} className='bg-[#228b22] font-bold text-sm text-white w-[40%] h-10 p-2 mt-2 rounded-lg flex justify-center items-center'>
                            Finalizar Cadastro
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}

            </AnimatePresence>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CadastroMobile;